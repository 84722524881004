import React, { createContext, useState } from "react";

// styles
import "../css/header.module.css";
import colors from "styles/__colors.scss";

// imgs
import NotificationIcon from "imgs/svg components/Notification";

// libs
import { Menu } from "@material-ui/core";

// components
import Notifications from "./Notifications";

const MenuContext = createContext({
	onClose: () => {},
});

export default function NotificationsBtn({ counter }) {
	const [isActive, setIsActive] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setIsActive(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setIsActive(false);
	};

	return (
		<li styleName="nav-item nav-item_with-counter">
			<div hidden={counter === 0} styleName="counter">
				<span styleName="counter-text">{counter}</span>
			</div>
			<div
				className="icon_xs"
				aria-controls="notifications-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<NotificationIcon fill={isActive ? colors.primary100 : colors.base60} />
			</div>
			<Menu
				id="notifications-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				getContentAnchorEl={null}
			>
				<MenuContext.Provider
					value={{
						onClose: handleClose,
					}}
				>
					{Boolean(anchorEl) && <Notifications onClose={handleClose} />}
				</MenuContext.Provider>
			</Menu>
		</li>
	);
}

export { MenuContext };
