import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.module.css";

//utils
import { isPublicProfile } from "functions";

// libs
import { useTranslation } from "react-i18next";

// imgs
import DEFAULT_PROFILE_ICON from "imgs/Default_user_img.png";
import ProfileIcon from "imgs/svg components/Profile-icon.js";
import LogoutIcon from "imgs/svg components/Logout.js";
import SettingsIcon from "imgs/svg components/Settigns.js";
import WalletIcon from "imgs/svg components/Wallet-icon.js";

// components
import { DropdownStatic } from "components/common/dropdown";
import { logoutUser } from "functions";

export default function NavDropdown(props) {
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();
	const { user } = props;

	return (
		<li styleName="nav-item">
			<div onClick={() => setIsOpen(true)}>
				<img
					className="icon_md__round"
					src={user.imageLink || DEFAULT_PROFILE_ICON}
					alt="Menu"
					onError={(ev) => {
						ev.target.src = DEFAULT_PROFILE_ICON;
					}}
				/>
			</div>
			<DropdownStatic
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				position="bottom-left"
				style={{ minWidth: "200px" }}
			>
				<ul className="dropdown-list">
					{isPublicProfile(user) && (
						<li>
							<Link className="dropdown-item" to={`/${user.idUser}`}>
								<div className="icon_xs item-img">
									<ProfileIcon />
								</div>
								<div className="flex-col">
									<span className="text-1">{t("Primary.Profile")}</span>
								</div>
							</Link>
						</li>
					)}
					<li>
						<Link className="dropdown-item" to="/settings">
							<div className="icon_xs item-img">
								<SettingsIcon />
							</div>
							<div className="flex-col">
								<span className="text-1">{t("Settings.Screen.Title")}</span>
							</div>
						</Link>
					</li>
					{user?.settings?.showWallet !== false && (
						<li>
							<Link className="dropdown-item" to="/wallet">
								<div className="icon_xs item-img">
									<WalletIcon />
								</div>
								<div className="flex-col">
									<span className="text-1">
										{t("Wallet.Withdraw.TextField.WalletAddress.Placeholder")}
									</span>
								</div>
							</Link>
						</li>
					)}
					<li className="dropdown-item" onClick={logoutUser}>
						<div className="icon_xs item-img">
							<LogoutIcon />
						</div>
						<div className="flex-col">
							<span className="text-1">{t("Settings.Item.LogOut.Title")}</span>
						</div>
					</li>
				</ul>
			</DropdownStatic>
		</li>
	);
}
