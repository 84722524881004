import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import { unregister } from "./registerServiceWorker"; // registerServiceWorker
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Root from "./routes/root";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import colors from "styles/__colors.scss";
import { store } from "store";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.primary100,
		},
		error: {
			main: colors.additionalError100,
		}, // works
	},
	overrides: {
		MuiFilledInput: {
			root: {
				backgroundColor: colors.base8,
				borderRadius: "4px",
				"&$focused": {
					backgroundColor: colors.primary8,
				},
				"&:hover": {
					backgroundColor: colors.base20,
				},
				"&$error": {
					backgroundColor: colors.additionalError8,
				},
			},
			underline: {
				"&:before": {
					content: "none",
				},
				"&:after": {
					content: "none",
				},
			},
		},
	},
	typography: {
		useNextVariants: true,
	},
});

/* eslint-disable */
String.prototype.trunc = function (n) { if (!n) n = 50; return this.substr(0, n - 1).trim() + (this.length > n ? '...' : '');};
/* eslint-enable */

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<MuiThemeProvider theme={theme}>
				<I18nextProvider i18n={i18n}>
					<Root />
				</I18nextProvider>
			</MuiThemeProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);

unregister();

// registerServiceWorker();
