import { API } from "APIcalls/APIrequest";
import safeKey from "./safeKey";
import firstNotNullValue from "./firstNotNullValue";
import hashPassword from "./hashPassword";
import loginUser from "./login-user";
import logoutUser from "./logout-user";
import shaMessage from "./shaMessage";
import updateUser from "./update-user";
import validatePassword from "./validate-pin";
import validateEmail from "./validateEmail";
import validateStiringLength from "./validateStiringLength";
import aTokenInLocalstorage from "./aTokenInLocalstorage";
import arraysEqual from "./arraysEqual";
import getFirstBrowserLanguage from "./get-first-browser-language";
import sha1 from "./sha1";
import calculateMsgPrice from "./calculateMsgPrice";
import fondySignature from "./fondySignature";
import stringNotEmpty from "./stringNotEmpty";
import calculateDiscount from "./calculateDiscount";
import isiOS from "./isiOS";
import primLang from "./primLang";
import { resizeImage, resizeImageAsync } from "./resizeImage";
import getImageFormats from "./getImageFormats";
import getCurrencies from "./getCurrencies";
import isPagePreventUserSync from "./isPagePreventUserSync";
import uniqueArrayOfObjByKey from "./uniqueArrayOfObjByKey";
import formatDate from "./formatDate";
import momentLocaleImporter from "./momentLocaleImporter";
import handleResponseError from "./handleResponseError";
import bytesToHRSize from "./bytesToHRSize";
import { checkCountry, getCountryByIp } from "./checkCountry";
import { addPaymentScripts } from "./addTags";
import { escapeSpecialCharacters } from "./escapeSpecialCharacters";
import { md5, pbkdf2 } from "./hashFunctions";
import { isPublicProfile, isPublicRole } from "./isPublicProfile";
import toStripeAmount from "./toStripeAmount";
import getYoutubeThumbnail from "./getYoutubeThumbnail";
import matchYoutubeUrl from "./matchYoutubeUrl";
import matchVimeoUrl from "./matchVimeoUrl";
import waitForDropdownClose from "./waitForDropdownClose";
import urlify from "./urlify";
import dataURLtoFile from "./dataURLtoFile";
import getVideoThumbnail from "./getVideoThumbnail";

// common
import lastArrayElem from "./lastArrayElem";
import { removeEmptyNested, removeEmpty } from "./removeEmpty";

// API
import { storeAToken } from "APIcalls/APIrequest";
import {
	errorCodes,
	errorCodeTexts,
	isErrorHandled,
	isErrorCodeHandled,
} from "APIcalls/errorCodes";

// products
import servicePriceLabel, {
	productPriceLabel,
} from "./products/servicePriceLabel";
import currencyRate from "./products/currencyRate";
import { subscriptionEventAboutLoc } from "./products/subscriptionEventLoc";

// purchase form
import buyButtonText, {
	buyButtonSubCaption,
} from "./purchase form/buyButtonText";

export * from "./localizationStrings";
// to refactor: change import statetments to 'exportDefaultFrom' syntax
// Add @babel/plugin-proposal-export-default-from (https://git.io/vb4yH) to the 'plugins' section of your Babel config to enable transformation.

export {
	safeKey,
	firstNotNullValue,
	hashPassword,
	loginUser,
	logoutUser,
	shaMessage,
	updateUser,
	validatePassword,
	validateEmail,
	validateStiringLength,
	aTokenInLocalstorage,
	arraysEqual,
	getFirstBrowserLanguage,
	sha1,
	addPaymentScripts,
	fondySignature,
	storeAToken,
	calculateMsgPrice,
	stringNotEmpty,
	calculateDiscount,
	escapeSpecialCharacters,
	isiOS,
	getImageFormats,
	API,
	md5,
	pbkdf2,
	primLang,
	resizeImage,
	isPublicRole,
	isPublicProfile,
	checkCountry,
	getCountryByIp,
	getCurrencies,
	servicePriceLabel,
	buyButtonText,
	currencyRate,
	buyButtonSubCaption,
	isPagePreventUserSync,
	uniqueArrayOfObjByKey,
	formatDate,
	momentLocaleImporter,
	errorCodes,
	errorCodeTexts,
	isErrorHandled,
	isErrorCodeHandled,
	handleResponseError,
	bytesToHRSize,
	toStripeAmount,
	lastArrayElem,
	getYoutubeThumbnail,
	matchYoutubeUrl,
	matchVimeoUrl,
	waitForDropdownClose,
	urlify,
	subscriptionEventAboutLoc,
	removeEmptyNested,
	removeEmpty,
	dataURLtoFile,
	getVideoThumbnail,
	resizeImageAsync,
	productPriceLabel,
};
