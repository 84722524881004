import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "json/locale/en.json";
import translationRU from "json/locale/ru.json";
import translationUK from "json/locale/uk.json";

const i18nInitOptions = {
	load: "languageOnly",
	whitelist: ["en", "ru", "uk"],
	// we init with resources
	resources: {
		en: {
			translations: translationEN,
		},
		ru: {
			translations: translationRU,
		},
		uk: {
			translations: translationUK,
		},
	},
	// lng: 'en',
	// lng: getFirstBrowserLanguage(),
	fallbackLng: "en",
	debug: false,

	// have a common namespace used around the full app
	ns: ["translations"],
	defaultNS: "translations",

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ",",
	},

	react: {
		wait: true,
	},
};
i18n.use(LanguageDetector).init(i18nInitOptions);

export default i18n;
