import React from "react";

function Icon({ className = "" }) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="-2 0 28 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
				strokeWidth="2"
			/>
			<path
				d="M5.5 10C5.32877 10 5.16155 9.98278 5 9.94999C3.85888 9.71836 3 8.70948 3 7.5C3 6.29052 3.85888 5.28164 5 5.05001C5.16155 5.01722 5.32877 5 5.5 5C5.58568 5 5.67035 5.00431 5.7538 5.01272"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M18.2422 10C18.4134 10 18.5806 9.98278 18.7422 9.94999C19.8833 9.71836 20.7422 8.70948 20.7422 7.5C20.7422 6.29052 19.8833 5.28164 18.7422 5.05001C18.5806 5.01722 18.4134 5 18.2422 5C18.1605 5 18.0797 5.00392 18 5.01158"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M6 19C6 16.7909 7.79086 15 10 15H14C16.2091 15 18 16.7909 18 19V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V19Z"
				strokeWidth="2"
			/>
			<path
				d="M19 12C18.4477 12 18 12.4477 18 13C18 13.5523 18.4477 14 19 14V12ZM21 17C20.4477 17 20 17.4477 20 18C20 18.5523 20.4477 19 21 19V17ZM19 14H19.6667V12H19V14ZM21.3333 17H21V19H21.3333V17ZM22 16.3333C22 16.7015 21.7015 17 21.3333 17V19C22.8061 19 24 17.8061 24 16.3333H22ZM24 16.3333C24 13.9401 22.0599 12 19.6667 12V14C20.9553 14 22 15.0447 22 16.3333H24Z"
				className="fill"
			/>
			<path
				d="M5 12C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14V12ZM3 17C3.55228 17 4 17.4477 4 18C4 18.5523 3.55228 19 3 19V17ZM5 14H4.33333V12H5V14ZM2.66667 17H3V19H2.66667V17ZM2 16.3333C2 16.7015 2.29848 17 2.66667 17V19C1.19391 19 0 17.8061 0 16.3333H2ZM0 16.3333C0 13.9401 1.9401 12 4.33333 12V14C3.04467 14 2 15.0447 2 16.3333H0Z"
				className="fill"
			/>
		</svg>
	);
}

export default Icon;
