import React, { useImperativeHandle } from "react";
import "./css/card.module.css";

// libs
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const CheckoutForm = React.forwardRef((props, ref) => {
	const { clientSecret, onComplete } = props;
	const stripe = useStripe();
	const elements = useElements();

	useImperativeHandle(ref, () => ({
		initCardPayment() {
			handleSubmit();
		},
	}));

	const handleSubmit = async () => {
		if (!stripe || !elements) {
			return;
		}

		const result = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement),
			},
		});

		if (result.error) {
			// console.log(result.error.message);
			onComplete("rejected");
		} else {
			// The payment has been processed!
			if (result.paymentIntent.status === "succeeded") {
				onComplete("success");
			}
		}
	};

	return <CardElement styleName="stripe-card-element" />;
});

export default CheckoutForm;
