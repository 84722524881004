import React from "react";
import { StripeProvider } from "..";
import CheckoutForm from "./CheckoutForm";

const CardElement = React.forwardRef((props, ref) => (
	<StripeProvider>
		<CheckoutForm ref={ref} {...props} />
	</StripeProvider>
));

export default CardElement;
