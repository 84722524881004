import i18n from "i18n";
const t = i18n.t.bind(i18n);

export default [
	{
		id: "posts",
		title: t("Profile.Posts.Tab"),
	},
	{
		id: "comments",
		title: t("Comments.Title"),
	},
];
